import React, { useEffect, useContext } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { FirebaseContext } from 'context';
import { useEventData } from 'hooks';
import { ProtectedRoute, RoleManagement, Reports, Emails, Miscellaneous, SEO } from 'components';

const customDropdownMenuStyles = () => ({
  container: (provided) => ({
    ...provided,
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'transparent',
    border: '1px solid #C4C4C4',
    borderRadius: '0px',
    display: 'flex',
    fontFamily: "'Helvetica Neue', sans-serif",
    fontSize: '1rem',
    height: '40px',
    paddingLeft: '1.25rem',
    marginTop: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    padding: state.isFocused ? '0 0 0 0.571em' : '0 0 0 0.571em',
    transition: 'none',
    cursor: 'pointer',
    height: '100%',
    width: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#002A3A',
    '&:hover': {
      color: '#002A3A'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#002A3A',
    margin: 0,
    width: '100%'
  }),
  menu: (provided) => ({
    ...provided,
    background: '#FFFFFF',
    border: 'none',
    borderRadius: '2px',
    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
    fontFamily: "'Helvetica Neue', sans-serif",
    fontSize: '1rem',
    left: '-1px',
    margin: '0',
    top: '100%',
    width: 'calc(100% + 2px)'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
    '::-webkit-scrollbar': {
      width: '11px'
    },
    '::-webkit-scrollbar-track': {
      background: '#002A3A'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#002A3A',
      height: '1.25rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#002A3A'
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#002A3A'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(255, 122, 161, 0.15)' : 'transparent',
    paddingLeft: '1.75rem',
    color: '#002A3A',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(255, 122, 161, 0.35)'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#002A3A',
    fontFamily: "'Helvetica Neue', sans-serif",
    fontSize: '1rem',
    fontStyle: 'italic',
    fontWeight: '300',
    margin: 0,
    opacity: 0.75
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#002A3A',
    fontWeight: '700',
    margin: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
});

function Admin() {
  const { loading, firebase, user } = useContext(FirebaseContext);

  const { edges: events } = useEventData();

  const sortedEvents = events
    .map(({ node }) => ({
      ...node.frontmatter,
      value: `Event ${node.frontmatter.eid} - ${node.frontmatter.name}`,
      label: `Event ${node.frontmatter.eid} - ${node.frontmatter.name}`
    }))
    .sort((a, b) => a.eid - b.eid);

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/');
    }
  }, [loading, firebase, user]);

  // useEffect(() => {
  //   let unsubscribeFromTimeWatched;

  //   if (firebase) {
  //     unsubscribeFromTimeWatched = firebase.admin.subscribeToTimeWatched({
  //       eid: '2',
  //       snapshot: (snapshot) => {
  //         if (!snapshot.empty) {
  //           const snaps = [];
  //           snapshot.forEach((_doc) => {
  //             snaps.push({
  //               uid: _doc.ref.parent.parent.id,
  //               ..._doc.data()
  //             });
  //           });
  //           const findPercentage = (partialValue, totalValue) =>
  //             Math.round((100 * partialValue) / totalValue);

  //           const lengthOfStream = 255; // Minutes

  //           const docs = snaps
  //             .map(({ uid, minutes }) => {
  //               let percentage = findPercentage(minutes, lengthOfStream);
  //               if (percentage > 100) {
  //                 percentage = 100;
  //               }
  //               return {
  //                 uid,
  //                 percentage
  //               };
  //             })

  //           console.log('docs', docs);

  //           // docs.forEach(({ uid, percentage }) => {
  //           //   updateDoc(doc(firebase.fsdb, 'users', uid), {
  //           //     percentageWatched: percentage
  //           //   });
  //           // });
  //         }
  //       }
  //     });
  //   }
  //   return () => {
  //     if (unsubscribeFromTimeWatched) {
  //       unsubscribeFromTimeWatched();
  //     }
  //   };
  // }, [firebase]);

  return (
    <ProtectedRoute isAdmin={user?.isAdmin}>
      <SEO pageSpecificTitle="Admin" />
      <MenuTab
        initial={{ y: -120 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.75, duration: 0.75 }}>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}>
          Admin Management & Utilities
        </motion.span>
      </MenuTab>
      <Container>
        <RoleManagement events={sortedEvents} customDropdownMenuStyles={customDropdownMenuStyles} />
        <Reports events={sortedEvents} customDropdownMenuStyles={customDropdownMenuStyles} />
        <Emails events={sortedEvents} customDropdownMenuStyles={customDropdownMenuStyles} />
        <Miscellaneous />
      </Container>
    </ProtectedRoute>
  );
}

const MenuTab = styled(motion.div)`
  align-items: center;
  background: #ff7aa1;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  left: 0;
  max-height: 60px;
  min-height: 60px;
  position: absolute;
  top: 3.75rem;
  width: 100%;
  span {
    color: #ffffff;
    font-size: 1.25rem;
    grid-column: 1 / 7;
    line-height: 1.5rem;
    padding: 0 1rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    span {
      grid-column: 2 / 12;
      padding: 0;
    }
  }
`;

const Container = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-column: 1 / 7;
  height: 100%;
  justify-content: center;
  margin: 4.75rem 0 3rem 0;
  padding: 0 1rem;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 4 / 10;
    margin-top: 10rem;
    padding: 0;
    gap: 6rem;
  }
`;

export default Admin;
